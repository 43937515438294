import React from 'react';
import { connect } from 'react-redux';
import { Link } from "react-router-dom";
import { isInRole, renderIf } from '../../modules/utils';
import './sidebar.css'
import Language_selector from "../language_selector";
import i18n from "../../i18n";

const mapStateToProps = (state) => {
    return { 
        uRole: state.role,
        company: state.company
    }
}

class Sidebar extends React.Component {
    constructor(props) {
      super(props);
      this.state = {};
      //this.gestoreClick = this.gestoreClick.bind(this);
    }
    render() {
        let logoUrl = 'https://firebasestorage.googleapis.com/v0/b/mytransport-246817.appspot.com/o/frontends%2FMbility%2F60.png?alt=media&token=7375bf3a-0a5a-4614-94ee-2e5ba568cf50';
        if(window.location.host=='gtp.mbility.app' || this.props.company=="GTP") {
            logoUrl = 'https://firebasestorage.googleapis.com/v0/b/mytransport-246817.appspot.com/o/frontends%2FGTP%2Flogo_gtp.png?alt=media&token=37da7904-9e63-4096-bd69-7c5c4c4520ad';
        } else if(window.location.host=='easy.mbility.app' || this.props.company=="EASY") {
            logoUrl = 'https://firebasestorage.googleapis.com/v0/b/mytransport-246817.appspot.com/o/frontends%2FEasy%2Flogo_cooperativa_easy.png.webp?alt=media&token=14e8eb0e-7940-4fe9-9499-a2795874b969';
        }
      return (
        <aside id="sidebar" className="main-sidebar sidebar-dark-primary elevation-4">
            <a href="/" className="brand-link">
                <img src={logoUrl} alt={this.props.company} className="brand-image img-circle elevation-3" />
                <span className="brand-text font-weight-light">{i18n.t("gestionale")}</span>
            </a>
            <div className="sidebar">
                <nav className="mt-2">
                    <ul className="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
                        <li className="nav-item">
                            <Link to="/home"><i className="nav-icon fa fa-dashboard"></i><p>{i18n.t("dashboard")}</p></Link>
                        </li>     
                        {renderIf(this.props.uRole=='admin', (<li className="nav-item">
                            <Link to="/analytics"><i className="nav-icon fa fa-chart-bar"></i><p>{i18n.t("analytics")}</p></Link>
                        </li>))}
                        <li className="nav-item">
                            <Link to="/map"><i className="nav-icon fa fa-map"></i><p>{i18n.t("mappa")}</p></Link>
                        </li> 
                        <li className="nav-item">
                            <Link to="/history"><i className="nav-icon fa fa-history"></i><p>{i18n.t("storico")}</p></Link>
                        </li>  
                        <li className="nav-item">
                            <Link to="/mezzi"><i className="nav-icon fa fa-van-shuttle"></i><p>{i18n.t("mezzi")}</p></Link>
                        </li>  
                        {renderIf(this.props.uRole=='admin', (<li className="nav-item">
                            <Link to="/rubrica"><i className="nav-icon fa fa-user"></i><p>{i18n.t("clienti")}</p></Link>
                        </li>))}
                        {renderIf(this.props.uRole=='admin', (<li className="nav-item">
                            <Link to="/users-role"><i className="nav-icon fa fa-user-ninja"></i><p>{i18n.t("impersona")}</p></Link>
                        </li>))}
                        {renderIf(this.props.uRole=='admin', (<li className="nav-item">
                            <Link to="/gestori"><i className="nav-icon fa fa-user-tie"></i><p>{i18n.t("gestori")}</p></Link>
                        </li>))}
                        <li className="nav-item">
                            <Link to="/autisti"><i className="nav-icon fa fa-id-card"></i><p>{i18n.t("autisti")}</p></Link>
                        </li>  
                        {renderIf(this.props.uRole=='admin', (<li className="nav-item">
                            <Link to="/checks"><i className="nav-icon fa fa-calendar"></i><p>{i18n.t("check_mezzo")}</p></Link>
                        </li>))}
                        {renderIf(this.props.uRole=='admin', (<li className="nav-item">
                            <Link to="/daily"><i className="nav-icon fa fa-refresh"></i><p>{i18n.t("pianificazione")}</p></Link>
                        </li>))}
                        {renderIf(this.props.uRole=='admin', (<li className="nav-item">
                            <Link to="/contratti"><i className="nav-icon fa fa-file"></i><p>{i18n.t("contratti")}</p></Link>
                        </li>))}
                        {renderIf(this.props.uRole=='admin', (<li className="nav-item">
                            <Link to="/companies"><i className="nav-icon fa fa-building"></i><p>{i18n.t("companies")}</p></Link>
                        </li>))}
                    </ul>
                </nav>
                <Language_selector/>
            </div>
        </aside>
      );
    }
  }
  
  export default connect(mapStateToProps, null)(Sidebar);
  